import { ParsedUrlQuery } from "querystring";
import { QUERY_KEY } from "./QueryKey";
import { StringQuery, DictionaryQuery } from "./QueryType";
import { Article } from "~/types/ArticleType";
import { StringSearchQuery } from "~/types/QueryType";
import { includes, valueOf } from "~/utils/typeUtil";

/**
 * クエリ構築のためのクラス
 */
export default class QueryBuilder {
  query: ParsedUrlQuery;

  constructor(query: ParsedUrlQuery) {
    this.query = { ...query };
  }

  build(): ParsedUrlQuery {
    return this.query;
  }

  transformQuery(
    queryKey: string,
    newValue: valueOf<StringQuery> | valueOf<DictionaryQuery>,
  ): void {
    let queryValue = "";

    switch (typeof newValue) {
      case "string":
        queryValue = newValue;
        break;
      case "object":
        for (const [key, value] of Object.entries(newValue)) {
          if (!value) continue;
          if (!queryValue) queryValue = "d";
          queryValue = [queryValue, key].join("-");
        }
        break;
    }

    const query = { ...this.query };

    // エリア系以外の条件が変更されたときには、a_id をそれに紐づくクエリに置換する
    if (
      QUERY_KEY.ARTICLE in query &&
      !includes(
        [
          QUERY_KEY.LINE,
          QUERY_KEY.STATION,
          QUERY_KEY.TODOFUKEN,
          QUERY_KEY.SHIKUGUN_GROUP,
          QUERY_KEY.SHIKUGUN,
        ],
        queryKey,
      )
    ) {
      const article = Article.find(String(query.a_id));
      if (article) {
        this.assignStringQuery(query, article.search_query);
      }
      delete query.a_id;
    }

    // 検索条件が変更されたときはページを1に戻す
    if (QUERY_KEY.PAGE in query && queryKey !== QUERY_KEY.PAGE) {
      delete query.p;
    }

    // 変更対象のクエリパラメータは最後に更新する
    query[queryKey] = queryValue;
    if (!queryValue) {
      delete query[queryKey];
    }

    this.query = this.sortQuery(query);
  }

  private assignStringQuery(targetQuery: ParsedUrlQuery, stringQuery: StringSearchQuery) {
    for (const [key, value] of Object.entries(stringQuery)) {
      if (!value) {
        delete targetQuery[key];
        continue;
      }
      if (!targetQuery[key]) {
        targetQuery[key] = value;
        continue;
      }

      if (value.startsWith("d")) {
        const t = (targetQuery[key] as string).split("-").slice(1);
        const s = (stringQuery[key] as string).split("-").slice(1);
        targetQuery[key] = Array.from(new Set(["d", ...t, ...s])).join("-");
      } else {
        targetQuery[key] = value;
      }
    }
  }

  private sortQuery(query: ParsedUrlQuery): ParsedUrlQuery {
    const orderedKeys = Object.values(QUERY_KEY);
    return orderedKeys.reduce<Record<string, string>>((result, key) => {
      if (key in query) {
        result[key] = query[key] as string;
      }
      return result;
    }, {});
  }
}
