import Line from "./LineType";
import { STATION_TABLE } from "~/data/STATION_TABLE";

type StationProps = {
  id: string;
  display_name: string;
  line_id: string;
  station_id: string;
  database_id: number;
  database_railway_id: number;
};

export default class Station {
  private readonly _id: string;
  private readonly _display_name: string;
  private readonly _line_id: string;
  private readonly _station_id: string;
  private readonly _database_id: number;
  private readonly _database_railway_id: number;

  // 初回生成時には作成しない変数
  private _line: Line | undefined;

  constructor(props: StationProps) {
    this._id = props.id;
    this._display_name = props.display_name;
    this._line_id = props.line_id;
    this._station_id = props.station_id;
    this._database_id = props.database_id;
    this._database_railway_id = props.database_railway_id;
  }

  get id(): string {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }

  get display_name_with_station(): string {
    return this._display_name + "駅";
  }

  get line_id(): string {
    return this._line_id;
  }

  get station_id(): string {
    return this._station_id;
  }

  get database_id(): number {
    return this._database_id;
  }

  get database_railway_id(): number {
    return this._database_railway_id;
  }

  get line(): Line {
    if (!this._line) {
      this._line = Line.findStrictly(this.line_id);
    }
    return this._line;
  }

  /*
   * FIXME: API化した際のリプレイス対象ここから
   */
  // すべての駅のうち、アクティブなものを返す
  static all(): Station[] {
    return STATION_TABLE.filter((data) => data.is_active === true).map((data) => new Station(data));
  }

  // アクティブな駅のID一覧を返す
  // NOTE: CanonicalStationType.stationIdsとの違いに留意
  // こちらは、同じ駅でも異なる沿線であれば全て返す
  static allIds(): string[] {
    return STATION_TABLE.filter((d) => d.is_active).map((d) => d.id);
  }

  // 特定のIDをもつ駅を返す(アクティブかどうかは問わない)
  static find(id: string): Station | undefined {
    const target = STATION_TABLE.find((data) => data.id === id);
    return target ? new Station(target) : undefined;
  }

  // 特定のIDをもつ駅を返す(アクティブかどうかは問わない)
  static findByDatabaseId(stationId: number): Station | undefined {
    const target = STATION_TABLE.find((data) => data.database_id === stationId);
    return target ? new Station(target) : undefined;
  }

  // 特定のIDをもつ駅を返す。なければエラーを投げる(アクティブかどうかは問わない)
  static findStrictly(id: string): Station {
    const target = this.find(id);
    if (!target) {
      throw new TypeError(`stationId ${id} is invalid.`);
    }
    return target;
  }

  // 特定の沿線に属する駅のうち、アクティブなものを返す
  static findByLineID(line_id: string): Station[] {
    return STATION_TABLE.filter((data) => data.line_id === line_id && data.is_active).map(
      (data) => new Station(data),
    );
  }

  // 同名駅を返す（同じstation_idを持つ駅を返す）
  // ただし、指定したIDの駅は除く
  static findSameStations(id: string): Station[] {
    const target = STATION_TABLE.find((data) => data.id === id);
    if (!target) return [];

    return STATION_TABLE.filter(
      (data) => data.id !== target.id && data.station_id === target.station_id,
    ).map((data) => new Station(data));
  }

  /*
   * API化した際のリプレイス対象ここまで
   */

  /*
   * FIXME: ID検索化した際のリプレイス対象
   * ※ リプレイスしやすいように、find_by_nameは作らない
   */
  static convertNameToID(display_name: string): string | undefined {
    return STATION_TABLE.find((data) => data.display_name === display_name)?.id;
  }
}
