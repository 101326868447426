import _ from "lodash";
import { AbstractRouteCondition } from "./Abstract/AbstractRouteCondition";
import { getRailway } from "~/types/Models/Railway";
import { getStation } from "~/types/Models/Station";
import { nonNullable } from "~/utils/typeUtil";

export class RailwayStationCondition extends AbstractRouteCondition {
  private readonly _railwayIds: number[];
  private readonly _stationIds: number[];

  constructor(_railwayIds: number[], _stationIds: number[]) {
    super();
    this._railwayIds = _.uniq(_railwayIds);
    this._stationIds = _.uniq(_stationIds);
  }

  get legacy(): boolean {
    return false;
  }

  protected setSourceData(): void {
    const railways = this._railwayIds.map((id) => getRailway(id)).filter(nonNullable);
    const stations = this._stationIds.map((id) => getStation(id)).filter(nonNullable);

    this._sourceData = {
      railways,
      stations,
    };
  }
}
